import React, { useContext } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Banner from "../../components/Services/VideoProduction/banner"
import DetailsSection from "../../components/Services/VideoProduction/detailsSection"
import VideoSection from "../../components/Services/VideoProduction/videoSection"
import Slider from "../../components/slider"
import ContactUs from "../../components/contactUs"
import Estimator from "../../components/PricingCalculator/estimator"

// Context API data
import { AppContext } from "../../store/AppContext"

const WebsiteMarketing = ({ data }) => {
  const globalData = useContext(AppContext)
  const {
    ogTitle,
    ogDescription,
    bannerTitle,
    bannerSubtitle,
    bannerDescription,
    bannerImage,
    storyTitle,
    storyDescription,
    approachTitle,
    approachDescription,
    approachSteps,
    whyInvestTitle,
    whyInvestDescription,
    videoList,
  } = data.video

  const bannerData = {
    bannerTitle,
    bannerSubtitle,
    bannerDescription,
    bannerImage,
  }

  const detailData = {
    storyTitle,
    storyDescription,
    approachTitle,
    approachDescription,
    approachSteps,
    whyInvestTitle,
    whyInvestDescription,
  }

  return (
    <Layout>
      <SEO title={ogTitle || "Video Production"} description={ogDescription} />
      <Banner data={bannerData} customNav={globalData?.state?.customNav} />
      <DetailsSection data={detailData} />
      <VideoSection data={videoList} />
      <Estimator singleService service="Video_Production" />
      <Slider blueBackground />
      <ContactUs />
    </Layout>
  )
}

export const query = graphql`
  query VideoPageQuery {
    video: contentfulServicesVideoPage {
      ogTitle
      ogDescription
      bannerTitle
      bannerSubtitle
      bannerDescription {
        json
      }
      bannerImage {
        title
        file {
          url
        }
      }

      storyTitle
      storyDescription {
        json
      }
      approachTitle
      approachDescription {
        json
      }
      approachSteps {
        id
        title
        description {
          description
        }
        image {
          title
          fixed(width: 80) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
      whyInvestTitle
      whyInvestDescription {
        json
      }
      videoList {
        videoUrl
        videoImage {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`

export default WebsiteMarketing
