import React, { useState } from "react"
import ReactPlayer from "react-player"

// Background Image component
import Background from "../../backgroundImage"
import Img from "gatsby-image"

import { PlayBtn } from "../../icons"

const VideoSection = ({ data }) => {
  const [playVideo, setPlayVideo] = useState(false)
  const [selectedVid, setSelectedVid] = useState(data[0])

  return (
    <Background type="greyInverse" className="py-8 md:py-12">
      <div className="max-w-6xl px-4 mx-auto md:px-8 xl:px-0">
        <div className="pb-8 mainVideoContainer">
          <div className="w-full mx-auto md:w-3/4">
            {playVideo ? (
              <div className="vid-player">
                <ReactPlayer
                  playing={true}
                  url={selectedVid.videoUrl}
                  width="100%"
                  height="100%"
                />
              </div>
            ) : (
              <div
                className="relative w-full cursor-pointer"
                onClick={() => setPlayVideo(true)}
              >
                <Img
                  fluid={selectedVid.videoImage.fluid}
                  alt={selectedVid.videoImage.title}
                  className="rounded-lg vid-placeholder"
                />
                <PlayBtn className="absolute w-1/5 play-btn md:w-auto" />
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-between mx-auto overflow-scroll vidThumbs md:w-3/4 md:pb-4 md:overflow-hidden">
          {data.map((vid, i) => {
            if (i > 0) {
              return (
                <div
                  key={i}
                  className="thumb"
                  onClick={() => setSelectedVid(vid)}
                >
                  <div className="w-full h-full ">
                    <Img
                      fluid={vid.videoImage.fluid}
                      alt={vid.videoImage.title}
                      className="w-full h-full rounded-md"
                    />
                  </div>
                  <div className="w-6 play-btn sm:w-12 lg:w-16">
                    <PlayBtn className="w-full h-full" />
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    </Background>
  )
}

export default VideoSection
