import React from "react"
import Img from "gatsby-image"
import RichTextRenderer from "../../RichTextRenderer"

import Arrow from "../../../assets/line.svg"

const Details = ({
  data: {
    storyTitle,
    storyDescription,
    approachTitle,
    approachDescription,
    approachSteps,
    whyInvestTitle,
    whyInvestDescription,
  },
}) => {
  return (
    <div className="max-w-6xl mx-auto px-4 md:px-8 xl:px-0">
      <div className="pb-4 xl:justify-cente">
        <div className="xl:w-3/4">
          <h2 className="text-white font-bold text-2xl xl:text-4xl uppercase siteFont">
            {storyTitle}
          </h2>
          <div className="Gray md:text-lg my-3 py-2">
            {RichTextRenderer(storyDescription.json)}
          </div>
        </div>

        <div className="mt-4">
          <div className="xl:w-3/4">
            <h2 className="text-white font-bold text-2xl xl:text-4xl uppercase siteFont">
              {approachTitle}
            </h2>
            <div className="Gray lg:text-lg my-3">
              {RichTextRenderer(approachDescription.json)}
            </div>
          </div>
          <div className="xl:flex text-white my-8  mx-auto xl:mx-0">
            {approachSteps.map((val, i) => {
              return (
                <div key={i} className="xl:flex xl:relative xl:w-1/4">
                  <div
                    className={`flex xl:text-center ${
                      i === 4 ? "xl:w-40" : "xl:w-auto"
                    }  xl:inline-block xl:my-4 relative xl:static`}
                  >
                    <div className="inline-block w-20 xl:w-20 md:mx-8">
                      <Img
                        fixed={val.image.fixed}
                        alt={val.image.title}
                        className="w-full"
                      />
                    </div>
                    <div
                      className={`w-64 ml-4 md:ml-0 ${
                        i === 4 ? "xl:w-40" : "xl:w-48"
                      } xl:ml-1`}
                    >
                      <h3 className="inline-block absolute text-xl xl:text-2xl xl:static xl:block pt-2 xl:pt-1 lg:mb-2 font-bold  xl:text-center siteFont">
                        {val.title}
                      </h3>
                      <p className="inline-block xl:block lg:text-lg mt-10 xl:m-0 absolute xl:static text-center Gray text-sm">
                        {val.description.description}
                      </p>
                    </div>
                  </div>
                  {i < "4" ? (
                    <div className="py-2 xl:py-10 w-2/4 xl:ml-40 xl:absolute xl:w-2/6">
                      <img
                        src={Arrow}
                        alt="arrow-icon"
                        className="hidden xl:block md::hidden"
                      />
                      <div className="xl:hidden block md:block pl-10 md:pl-16">
                        <div className="bg-blue-400 w-2 h-2 mb-2 rounded-full"></div>
                        <div className="bg-blue-400 w-2 h-2 mb-2 rounded-full"></div>
                        <div className="bg-blue-400 w-2 h-2 mb-2 rounded-full"></div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="xl:w-3/4 xl:justify-center">
        <h3 className="text-white font-bold text-2xl xl:text-4xl uppercase siteFont">
          {whyInvestTitle}
        </h3>
        <div className="Gray lg:text-lg my-3">
          {RichTextRenderer(whyInvestDescription.json)}
        </div>
      </div>
    </div>
  )
}

export default Details
